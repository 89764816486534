const config = {
  app: {
    name: String(process.env.REACT_APP_NAME),
  },
  env: String(process.env.REACT_APP_ENV),
  isDev: String(process.env.REACT_APP_ENV) === 'development',
  isProd: String(process.env.REACT_APP_ENV) === 'production',
  auth0: {
    domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
    clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
    audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
  },
  serverUrl: String(process.env.REACT_APP_SERVER_URL),
  name: String(process.env.REACT_APP_NAME),
  recaptchav3: String(process.env.REACT_APP_RECAPTCHAV3),
  accessTokenPassword: String(process.env.REACT_APP_ACCESS_TOKEN_PASSWORD),
  otto: {
    chainName: String(process.env.REACT_APP_CHAIN_NAME),
    endpoint: String(process.env.REACT_APP_ENDPOINT),
    multisigAddress: String(process.env.REACT_APP_MULTISIG_ADDRESS),
  },
  api: {
    baseUrl: String(process.env.REACT_APP_BASE_API_URL),
  },
  staking: {
    catalystListId: Number(process.env.REACT_APP_CATALYST_LIST_ID),
    stakingPoolId: Number(process.env.REACT_APP_STAKING_POOL_ID),
  },
  assets: {
    catalyst: Number(process.env.REACT_APP_CATALYST_TOKEN_ID),
    otto: Number(process.env.REACT_APP_OTTO_TOKEN_ID),
    plug: Number(process.env.REACT_APP_PLUG_TOKEN_ID),
    multiSignPartnerPlug: Number(process.env.REACT_APP_MULTI_SIGN_PARTNER_PLUG_TOKEN_ID),
    catalystVoucher: Number(process.env.REACT_APP_CATA_VOUCHER_TOKEN_ID),
  },
  sentry: {
    dsn: String(process.env.REACT_APP_SENTRY_DSN),
  },
  exchange: {
    plugRate: Number(process.env.REACT_APP_PLUG_OTTO_RATE),
  },
  eth: {
    chainId: Number(process.env.REACT_APP_ETHEREUM_CHAIN_ID),
    etherscanUrl: String(process.env.REACT_APP_ETHERSCAN_URL),
  },
  infura: {
    httpsUrl: String(process.env.REACT_APP_INFURA_HTTPS_URL),
  },
  web3Wallet: {
    walletConnectBridge: String(process.env.REACT_APP_WALLET_CONNECT_BRIDGE),
  },
  plugDepositContractAddress: String(process.env.REACT_APP_PLUG_DEPOSIT_CONTRACT_ADDRESS),
  plugTokenAddress: String(process.env.REACT_APP_PLUG_TOKEN_ADDRESS),
  isCustodyEnabled: process.env.REACT_APP_CUSTODY_ENABLED === 'true',
  isMaintenanceMode: process.env.REACT_APP_IS_MAINTENANCE_MODE === 'true',
  ieo: {
    id: Number(process.env.REACT_APP_IEO_ID) || 0,
    duration: Number(process.env.REACT_APP_NEW_BLOCK_DURATION) || 6000,
    redeemRatio: Number(process.env.REACT_APP_CATA_VOUCHER_RATIO) || 1,
  },
};

export const POLKADOT_EXTENSION_LINK = 'https://polkadot.js.org/extension/';
export const METAMASK_EXTENSION_LINK =
  'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
export const EXTENSION_INFO_LINK =
  'https://ottoblockchain.medium.com/ott%C3%B3-onboarding-wallet-binding-explainer-3094ce775723';
export default config;
