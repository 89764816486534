/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import config from 'config';

import * as Sentry from '@sentry/react';

export const fetchRequest = async (token: string, input: RequestInfo, init?: RequestInit) => {
  let response;
  let error;
  try {
    const res = await fetch(input, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...init,
    });
    if (res.status !== 204) {
      response = await res.json();
    } else {
      response = { statusCode: 204 };
    }
    if (!res.ok) {
      error = 'request failed';
    }
  } catch (err) {
    console.error('Send request error', err);
    Sentry.captureException(err);
    error = err;
  }
  return { response, error };
};

export const verifyRecaptchaToken = (token: string, action: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/recaptcha/verify`, {
    method: 'POST',
    body: JSON.stringify({ token, action }),
  });

export const register = (email: string, password: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/register`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });

export const basicLogin = (email: string, password: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });

export const otpLogin = (otp: string, mfaToken: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/login`, {
    method: 'POST',
    body: JSON.stringify({ otp, mfaToken }),
  });

export const startMfaFlow = (mfaToken: string, email: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/authenticators?email=${email}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${mfaToken}`,
    },
  });

export const getProfile = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/profile`, {
    method: 'GET',
  });

export const saveProfile = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/profile`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const signMessage = (token, network: 'ETH' | 'OTTO', isBinding: boolean) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/sign-message`, {
    method: 'POST',
    body: JSON.stringify({ network, isBinding }),
  });

export const addEthWallet = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/eth-wallet`, {
    method: 'Put',
    body: JSON.stringify(payload),
  });

export const removeEthWallet = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/eth-wallet`, {
    method: 'Delete',
    body: JSON.stringify(payload),
  });

export const initIDVerificationRequest = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/id/init`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const initDocVerificationRequest = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/init`, {
    method: 'POST',
  });

// this will send the uploaded document to Jumio for doc verification
export const startDocVerificationRequest = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/start`, {
    method: 'POST',
  });

export const updateIdVerificationStatus = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/id/status`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const updateDocVerificationStatus = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/status`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const bindWalletAddress = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/bind`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const unbindWalletAddress = (token, payload) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/unbind`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const createNFT = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/nft/create`, {
    method: 'POST',
  });

export const changePassword = async (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/change_password`, {
    method: 'POST',
  });

export const userForgotPassword = async (email: string) => {
  const url = `${config.serverUrl}/v1/users/forgot_password`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email }),
  });

  return res.ok;
};

export const resetMFA = async (token: string, auth0Id: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/reset_mfa`, {
    method: 'POST',
    body: JSON.stringify({ auth0Id }),
  });

export const verfiyEmail = async (email: string) => {
  const url = `${config.serverUrl}/v1/users/verify-email?email=${email}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  });

  return res.ok ? { response: res.json() } : { error: 'Resend email failed' };
};

export const getUserOrders = async (token: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/user/orders`, {
    method: 'GET',
  });

export const updateOttoSignature = (token, payload: { signature: string }) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/signature`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const getUserClaimRewards = (
  token,
  source: 'pre-mining' | 'plug-deposit' = 'pre-mining',
  page = 0,
  pageSize = 10
) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/claim/rewards?skip=${
      page * pageSize
    }&limit=${pageSize}&source=${source}`,
    {
      method: 'GET',
    }
  );

export const getUserClaimRewardAmount = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/claim/rewards_total?status=pending`, {
    method: 'GET',
  });

export const getFeatureFlags = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/feature-flags`, {
    method: 'GET',
  });

export const updateServerSign = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/sign`, {
    method: 'PUT',
  });

export const getUserAddressBookList = (token, skip?: number, limit?: number, search?: string) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/address-books?skip=${skip || 0}&limit=${limit || 10}&search=${
      search || ''
    }`,
    {
      method: 'GET',
    }
  );

export const addUserAddressBook = (token, payload: { name?: string; walletAddress: string }) =>
  fetchRequest(token, `${config.serverUrl}/v1/address-books`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const updateAddressBook = (
  token,
  payload: { id: string; name?: string; walletAddress: string }
) => {
  const { id, name, walletAddress } = payload;
  return fetchRequest(token, `${config.serverUrl}/v1/address-books/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ name, walletAddress }),
  });
};

export const deleteAddressBook = (token, id: string) => {
  return fetchRequest(token, `${config.serverUrl}/v1/address-books/${id}`, {
    method: 'DELETE',
  });
};
