import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Button, Divider, Typography } from 'antd';

import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAuthContext from 'providers/auth-provider';
import ProfileProvider from 'providers/profile-provider';

import styles from './s.module.scss';

interface UserNavItem {
  title: string;
  link: string;
  active: boolean;
}

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <Link to="/signup">
        <Button className={`${styles.btn}`} type="link" size="large">
          {t('sign_up')}
        </Button>
      </Link>
      <Divider type="vertical" />
      <Link to="/login">
        <Button className={`${styles.btn} ${styles.themed_btn}`} type="link" size="large">
          {t('login')}
        </Button>
      </Link>
    </>
  );
};

const UserNav = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (!user) {
    return null;
  }

  const items: UserNavItem[] = [
    {
      title: t('nav_account'),
      link: '/user/account',
      active: pathname === '/user/account',
    },
    {
      title: t('nav_dashboard'),
      link: '/dashboard',
      active: pathname === '/dashboard',
    },
  ];

  const renderUserNavItem = (item: UserNavItem) => {
    return (
      <Typography.Title key={item.title} level={5} className={`${styles.nav_account}`}>
        <NavLink
          to={item.link}
          exact
          // className="nav-link"
          style={item.active ? { color: '#e9ff00' } : {}}
          // activeClassName={styles.active}
        >
          {item.title}
        </NavLink>
      </Typography.Title>
    );
  };
  return <>{items.map((item) => renderUserNavItem(item))}</>;
};

const TopNav: React.FC = () => {
  const { accessToken, logout } = useAuthContext();
  const { t } = useTranslation();

  return accessToken ? (
    <ProfileProvider>
      <div className={styles.nav}>
        <UserNav />
        <Typography.Title level={5} onClick={logout}>
          <a>
            {t('nav_logout')} <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </a>
        </Typography.Title>
      </div>
    </ProfileProvider>
  ) : (
    <Login />
  );
};

export default TopNav;
